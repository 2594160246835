<template>
  <div v-bind="getRootProps()"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="toggleActive"
    :class="{ 'active-dropzone': active }"
    class="dropzone"
    v-if='!isUploading'>
      <input v-bind="getInputProps()"  id="dropzoneFile" class="dropzoneFile" accept=".jpg, .jpeg, .png, .webP, .gif"/>
  </div>
  <p class="uploading" v-if='isUploading'>Upload Image</p>
  <div v-if='isUploading' class='gauge'>
    <highcharts :p="percentComplete" :h="ht"></highcharts>
  </div>
  
</template>

<script>
/* eslint-disable */
import {inject, ref} from 'vue';
import { useDropzone } from 'vue3-dropzone';
import MapChart from '../highcharts/solidgauge';
import {useStore} from "vuex";
import ProcessingImage from '../spinners/ProcessingImage.vue';

export default {
  name: 'DropZone',
  inject: ['apiPath'],
  components: {
    highcharts: MapChart,
    ProcessingImage: ProcessingImage
  },
  props: ['docid', 'n','index','height'],
  emits: ['uploadCompleted','uploadStarted'],
  setup(props,context) {
    const store = useStore();
    const apiPath = inject('apiPath');
    const percentComplete = ref(0.0);
    const isUploading = ref(false);
    let ht = ref(0);
    ht = props.height;
    console.log("Height set in cloudflare comp" + ht);

    async function getOneTimeUploadUrl() {
      let response = await fetch(`${apiPath}cloudflareGetUploadLinkImage?token=${store.state.usertoken}`);
      return await response.text();
    }
    function progressHandler(event) {
      let bytes = event.loaded;
      let showbytes = 0;
      let units = '';
      let total = 0;
      let percent = 0;
      if (bytes > 1000) {
        showbytes = bytes / 1000;
        units = 'KB';
      }
      if (bytes > 1000000) {
        showbytes = bytes / 1000000;
        units = 'MB';
      }
      const loaded = `${showbytes.toFixed(2)} ${units}`;

      bytes = event.total;
      showbytes = 0;
      units = '';
      if (bytes > 1000) {
        showbytes = bytes / 1000;
        units = 'KB';
      }
      if (bytes > 1000000) {
        showbytes = bytes / 1000000;
        units = 'MB';
      }
      total = `${showbytes.toFixed(2)} ${units}`;
      percent = (event.loaded / event.total) * 100;
      percentComplete.value = Math.round(percent);
    }

    const completeHandler = (event) => {
      const data = JSON.parse(event.target.responseText);
      isUploading.value = false;
      if (data.success) {
        context.emit("uploadCompleted", props.index);
      } else {
        context.emit("uploadCompleted", -1, data.errors[0].message);
      }
    }

    function errorHandler(event) {
      context.emit("uploadCompleted",-1);
    }

    function abortHandler(event) {
      context.emit("uploadCompleted",-2);
    }

    async function uploadFile(files) {
      const file = files[0];
      const formdata = new FormData();
      const ajax = new XMLHttpRequest();
      const doctype = 'posts';
      if (doctype == null) return false;
      const uploadURL = await getOneTimeUploadUrl();
      formdata.append('file', file);
      ajax.upload.addEventListener('progress', progressHandler, false);
      ajax.addEventListener('load', completeHandler, false);
      ajax.addEventListener('error', errorHandler, false);
      ajax.addEventListener('abort', abortHandler, false);
      ajax.open('POST', uploadURL);
      ajax.send(formdata);
      console.log(file, uploadURL);
      context.emit("uploadStarted",uploadURL,props.index);
      return true;
    }

    function onDrop(acceptFiles, rejectReasons) {
      uploadFile(acceptFiles);
      isUploading.value = true;
      console.log(rejectReasons);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };

    return {
      active,
      ht,
      percentComplete,
      isUploading,
      progressHandler,
      completeHandler,
      toggleActive,
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
};
</script>

<style lang="scss" scoped>

*{
  -webkit-transition: all 0ms ease !important;
  transition: all 0ms ease!important;
}
.delete{
  height: 32px;
  width: 32px;
  position: absolute;
  background-color: #fff;
  border-radius: 16px;
  top: 5px;
  right: 5px;
  background-image: url(../../../public/img/close_gray.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}

.dropzone {
  position:absolute;
  width: inherit;
  height: inherit;
  left: 0px;
  border-radius: 16px;
  box-sizing: border-box;
  transition: 0.3s ease all;
  text-align: left;
  background-image: url(../../../public/img/library/uploader.svg);
  background-position: center 80px;
  background-repeat: no-repeat;
  background-size: 35%;
  font-weight: 400;
  text-align: center;
  color: #787878;
  line-height: 16px;
  white-space: initial;
  padding-top: 55px;
  height: 100%;
  z-index: 0;
}
.dropzone::after {
  content: 'Select to upload image max size:  20MB';
  width: 244px;
  position: absolute;
  display: inline-block;
  left: 50%;
  // transform: translateX(-50%);
  max-width: 100%;
  top: 65%;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}
.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: #41b883;
  label {
    background-color: #fff;
    color: #41b883;
  }
}
.gauge {
  position: absolute;
  border-radius: 16px;
  //border: dashed 2px green;
  width: 100%;
  height: 100%;
}
.highcharts-class{
      height: 50px !important;
    }
@media screen and (max-width: 599px) and (orientation: portrait){
  .action_button{
    // .gauge{
    //   display: none;
    // }
    
  }
  .dropzone {
    height: 100%;
    width: 100%;
    background-image: url(../../../public/img/library/uploader.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 45px;
    background-size: 30%;
  }
  .dropzone::after {
    width: 230px;
    margin-left: -115px;
    bottom: 15px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    bottom: 60px;
  }
}
</style>
